@import '~styles/base.module.scss';

header {
  padding: 0 !important;
}

.fullScreenHeader {
  font-size: 45px !important;
  padding-bottom: $space-s;

  @media (max-height: '800px') {
    font-size: 40px;
  }

  @media (max-height: '700px') {
    font-size: 38px;
  }

  @include media('<tablet') {
    padding-top: $space-m;
    font-size: 20px !important;
  }
}

.verticallyAligned {
  margin: auto;
  text-align: center;

  @include media('<=991px') {
    padding-bottom: 8px !important;

    h5 {
      font-size: 16px !important;
    }
  }
}

header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

h5 {
  opacity: 0.7;
}

.main_logo {
  margin-left: $space-m;
  width: 200px;
  max-height: 100px;

  @media (max-height: '700px') {
    max-height: 70px;
  }

  @include media('<992px') {
    display: none;
  }
}

.logo {
  margin-right: $space-m;
  max-height: 100px;

  @include media('<992px') {
    display: none;
  }
}

.empty {
  margin-right: $space-m;
  max-height: 100px;
  width: 200px;

  @media (max-height: '700px') {
    max-height: 70px;
  }

  @include media('<992px') {
    display: none;
  }
}

.responsiveLogo {
  display: none;

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 100%;
    max-height: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.responsiveUserLogo {
  display: none;

  @include media('<991px') {
    width: 100%;
    max-height: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $space-m;
  }
}

.headerLogo {
  height: 50px;
}
