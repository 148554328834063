@import '~styles/base.module.scss';

.modalContainer {
  max-width: 472px !important;
  width: 472px !important;
  flex-basis: 35em;
  margin: auto;
  margin-top: 30px;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;
  max-height: 90vh !important;

  scrollbar-width: none;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100vh !important;
    border: none !important;
    overflow: auto;
    padding: 20px !important;
    backdrop-filter: none !important;
    max-height: 100vh !important;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<tablet') {
    margin: 5px;
  }
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-inline: 10px;
  height: 7%;
  padding-bottom: 17px;
  padding-top: 10px;
}

.subheader {
  padding: 4px;
  font-size: 12px;
}

.subheaderSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px 10px;
  font-size: 12px;
}

.input {
  margin-top: 1.5em !important;
  margin-bottom: 0.25em !important;
  position: relative;
  width: 100%;
  padding: 0.3%;

  input {
    padding: 18.5px 14px !important;
  }
}

.emailInput {
  margin-top: 0.5em !important;
  margin-bottom: 0.25em !important;
  position: relative;
  width: 90%;
  padding: 0.3%;

  input {
    padding: 18.5px 14px !important;
  }
}

.inputDescription {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;

  input {
    padding-top: $space-m !important;
    padding-left: $space-l !important;
  }

  align-items: center;
  color: $color-primary !important;

  label {
    margin: auto;
    font-size: 14px;
  }

  p {
    align-self: flex-start;
    margin-left: 10px;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0;
}

.buttonSiguiente {
  margin-top: 100px;
  border-radius: 30px;
  background-color: $color-primary;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.error {
  color: $color-red;
  margin: 0 !important;
}

.shareProject {
  margin-top: 15px;
  font-weight: 500;
  font-size: 15px;
}

.subTitle {
  color: #424242cc;
  margin-top: 15px;
  font-size: 18px;
  margin-bottom: 8px;
}

.buttonCancelar {
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.email {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapperInfoButton {
  display: flex;
  flex-flow: row nowrap;
  float: right;
  border-radius: 38px;
  border-color: transparent;
  padding-left: 10px;
  text-transform: unset !important;
  background-color: $color-white;

  &:hover {
    cursor: pointer !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    font-size: 0;
  }
}

.infoButton {
  padding: 0 !important;
  position: relative;
  color: rgba(66, 66, 66, 0.8);

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  &:hover {
    background-color: $color-white !important;
    color: rgba(66, 66, 66, 1);
  }
}

.wrapperDataInfo {
  display: flex !important;
  align-content: baseline !important;
  align-items: center;
  margin-left: auto;
  justify-content: space-between;
}

.cajaEmail {
  background-color: $color-white;
  max-width: 400px;
  border-radius: 30px;
  z-index: 7000 !important;
  position: fixed;
  top: 40%;
  left: 49%;
  transform: translate(-54%, -50%);
  margin: 2%;
  padding: 30px;
  padding-bottom: 13px;
  box-shadow: 0 0 5px grey !important;

  @include media('<small') {
    margin: 0%;
    min-width: 300px;
    padding: 5%;
    top: 50%;
    left: 50%;
    transform: translate(-54%, -50%);
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6000;
}

.cselect {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}

.wrapperInfo {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  max-height: 55px !important;
  align-items: center;
  margin: 0 !important;

  span {
    font-size: 14px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.custom {
  margin-bottom: 0 !important;
  width: 90% !important;
}

.roleButtonWrapper {
  margin-top: 17px;
}

.moreInfoButtonWrapper {
  margin-top: 20px;
  margin-bottom: 10px;
}

.moreInfoButton {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  color: $color-white;
  border-radius: 64px;
  border-color: transparent;
  padding: 10px 20px;
  font-weight: 500;

  &:link {
    text-decoration: none;
  }

  &:hover {
    background-color: $color-primary-dark;
    color: white;
  }
}

.addUser {
  margin-top: 1.5em;
  margin-bottom: 30px;
  background-color: $color-white;
  color: $color-primary;
  border: 1px solid $color-primary !important;
  box-shadow: none !important;
  margin-top: none !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonSave {
  margin-top: 100px !important;
  border-radius: 78px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }

  @include media('<small') {
    margin-top: 70px !important;
  }
}
