@import '~styles/base.module.scss';

.modalContainer {
  max-width: 472px !important;
  width: 472px !important;
  flex-basis: 35em;
  margin: auto;
  margin-top: 30px;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;
  max-height: 90vh !important;

  scrollbar-width: none;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100vh !important;
    border: none !important;
    overflow: auto;
    padding: 20px !important;
    backdrop-filter: none !important;
    max-height: 100vh !important;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
}

.subTitle {
  font-size: 1.2em;
}

.modal {
  margin: 60px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<tablet') {
    margin: 5px;
  }
}

.text {
  margin: $space-s 0px;
  font-weight: 300;
  font-size: 1em;
}

.searchAddWrapper {
  width: 100% !important;
  margin-bottom: 0.5em;
}

.searchBar {
  width: 100% !important;
  margin-bottom: $space-m !important;
  border-color: $color-light-gray;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  label {
    display: none;
    transition: none;
    transform: none !important;
    border-color: $color-light-gray;
  }

  div {
    border-radius: 1px solid $color-light-gray;
    font-size: 13px;
    padding-right: 0;

    &:focus {
      background-color: $color-light-gray;
    }

    svg {
      color: #333333;
      width: 22px;
      height: 22px;
    }
  }

  fieldset {
    border-radius: 64px;
    border-color: $color-light-gray !important;
    border-width: 1px !important;
    top: 0px !important;

    legend {
      display: none;
    }
  }

  input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 13.5px;
    color: inherit;
    font-family: inherit;
    font-weight: 400;
    background-clip: padding-box;
    border-radius: 1px solid $color-light-gray;
    transition: border-color 120ms;
    height: auto;

    &:focus {
      border-color: $color-extra-light-gray;
      background-color: $color-extra-light-gray;
      -webkit-box-shadow: 0 0 0 30px white inset;
    }

    &::placeholder {
      font-size: 14px;
      color: $color-black;
    }
  }

  @include media('<tablet') {
    width: 100% !important;
    padding: 0 $space-m !important;
    margin-bottom: 0 !important;
  }
}

.checkboxEmailWrapper {
  margin-top: 2% !important;
  margin-bottom: $space-m !important;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 300px;
}

.checkboxFormDiv {
  width: 100% !important;
  padding-top: 0;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  margin-left: 10px;

  label {
    display: flex;
    align-items: center;
    margin: 0 !important;
    margin: 0px !important;
    margin-left: 5px !important;
  }

  span {
    font-size: 1rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-right: 2px;
    padding: 3px 0px 3px 0px;
    border-radius: 4px;
  }
}

.checkboxForm {
  display: flex;
  align-items: center;
  margin: 0 !important;

  span {
    font-size: 14px !important;
    margin-right: 10px;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.confirmed {
  color: black;
  margin-bottom: 0 !important;
}

.notConfirmed {
  color: lightgray;
  margin-bottom: 0 !important;
}

.checkbox {
  cursor: pointer;
  position: relative;
  margin-right: 7px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #009be5 !important;
    background-color: $color-primary !important;
  }
}

.checkedBox {
  svg {
    color: #009be5 !important;
    margin: 0;
    padding: 0;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0;
}

.deleteButton {
  margin-top: 34px;
  border-radius: 78px !important;
  background-color: $color-error;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-secundary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.selectAllCheckbox {
  display: flex;
  align-items: flex-start;
  margin-left: 0 !important;
  margin-top: $space-s;

  span {
    font-size: 18px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.buttonCancelar {
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgb(198, 230, 248);
  }

  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.error {
  margin-top: 4px;

  small {
    color: $color-red;
  }
}
