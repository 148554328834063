@import '~styles/base.module.scss';

.componentLoggedOut {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  height: $aux-header-height;
  box-shadow: 0 0 0 0 !important;
  background-color: rgba(255, 255, 255, 1) !important;

  @include media('<tablet') {
    height: 60px;
  }
}

.navHelpItemLogo {
  margin-right: $space-s;
  opacity: 0.8;
  width: 18px;
}

.navUserItemLogo {
  margin-right: $space-s + 1;
  opacity: 0.8;
  width: 18px;
}

.componentLoggedIn {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  height: $aux-header-height;
  box-shadow: 0 0 0 0 !important;
  background-color: rgba(204, 235, 250, 1) !important;

  @include media('<tablet') {
    display: none;
    visibility: hidden;
  }
}

.logoWrapper {
  height: 40px;

  @include media('>=tablet') {
    display: none;
    visibility: hidden;
  }
}

.logoImage {
  height: 40px;
  color: #545e60;
}

.content {
  position: relative;
  padding: 0;
  margin: 0 auto;
  max-width: $view-max-width;
  padding-left: $space-m;
  padding-right: $space-m;
  min-height: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

.homePage {
  display: flex;
}

.auxHeaderContent {
  display: flex;
  position: absolute;
  right: 0;
}

.help {
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  align-items: center;

  @include media('<tablet') {
    display: none;
  }
}

.user {
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  align-items: center;

  @include media('<tablet') {
    display: none;
  }
}

.navItem {
  margin-right: $space-m;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color ease 0.3s;
  color: inherit;

  @include media('<tablet') {
    margin-right: $space-s;
    min-width: 48px;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonLanguages {
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-size: 14px;
  padding: 0;
  border-radius: 0;
  z-index: 0;
  color: rgba(66, 66, 66, 0.8);
  text-transform: none;

  &:hover {
    background: transparent;
    border-radius: 0;
    padding-bottom: 0;
  }
}

.menuHelpPaper {
  border: 1px solid #dddddd;
  border-radius: 15px !important;
  padding: 0;
  text-align: center;
  margin-top: 10px;
  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 264px;
}

.menuUserPaper {
  border: 1px solid #dddddd;
  border-radius: 15px !important;
  padding: 0;
  margin-top: 10px;
  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 156px;
}

.MuiPaper-rounded {
  border-radius: 15px;
}

.menuItem {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.5;
  font-family: 'Roboto';

  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  background-color: none;

  &:hover {
    background-color: rgba(0, 155, 229, 0.4) !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.mobileNav {
  display: none;

  @include media('<tablet') {
    display: flex;
    flex-flow: row nowrap;
  }
}

.mobileMenu {
  position: fixed;
  left: 0;
  top: $header-height;
  right: 0;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.userOptionsLogo {
  margin: 3%;
}

.userOptionsText {
  color: rgba(0, 0, 0, 0.8);
  padding: 5%;
  font-size: 14px;
}
